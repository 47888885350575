import React, { Component } from 'react';
import { MapContainer, Circle, FeatureGroup, Tooltip, LayersControl, TileLayer, Polyline } from 'react-leaflet'
import { latLngBounds } from 'leaflet'

export class GPSMapContainer extends Component {
    constructor(props) {
        super(props);

        var showPolyline = [];
        var lat1 = [];
        var long1 = [];
        var lat2 = [];
        var long2 = [];

        //Create refs
        this.captureCircleRefs = [];
        if (this.props.capturedataLoaded) {     
            this.props.capturedata.map(row => {
                this.captureCircleRefs.push(React.createRef());
            });
        }
        this.missingCircleRefs = [];
        this.missingPolylineRefs = [];
        if (this.props.missingdataLoaded) {
            this.props.missingdata.map(row => {
                this.missingCircleRefs.push(React.createRef());
                this.missingPolylineRefs.push(React.createRef());
                showPolyline.push(false);
                lat1.push(0);
                long1.push(0);
                lat2.push(0);
                long2.push(0);
            });
        }

        this.state = {
            //Init - config
            pageCaptureClickedIndex: -1,
            pageCaptureClickedData: {},
            pageMissingClickedIndex: -1,
            pageMissingClickedData: {},
            pageMissingShowPolyline: showPolyline,
            pageMissingShowPolyline_Latitude1: lat1,
            pageMissingShowPolyline_Longitude1: long1,
            pageMissingShowPolyline_Latitude2: lat2,
            pageMissingShowPolyline_Longitude2: long2,
            pageResetNextClick: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.capturedata != prevProps.capturedata || this.props.missingdata != prevProps.missingdata) { //re-init
            var showPolyline = [];
            var lat1 = [];
            var long1 = [];
            var lat2 = [];
            var long2 = [];

            //Create refs
            this.captureCircleRefs = [];
            if (this.props.capturedataLoaded) {
                this.props.capturedata.map(row => {
                    this.captureCircleRefs.push(React.createRef());
                });
            }
            this.missingCircleRefs = [];
            this.missingPolylineRefs = [];
            if (this.props.missingdataLoaded) {
                this.props.missingdata.map(row => {
                    this.missingCircleRefs.push(React.createRef());
                    this.missingPolylineRefs.push(React.createRef());
                    showPolyline.push(false);
                    lat1.push(0);
                    long1.push(0);
                    lat2.push(0);
                    long2.push(0);
                });
            }

            this.setState({
                //Init - config
                pageCaptureClickedIndex: -1,
                pageCaptureClickedData: {},
                pageMissingClickedIndex: -1,
                pageMissingClickedData: {},
                pageMissingShowPolyline: showPolyline,
                pageMissingShowPolyline_Latitude1: lat1,
                pageMissingShowPolyline_Longitude1: long1,
                pageMissingShowPolyline_Latitude2: lat2,
                pageMissingShowPolyline_Longitude2: long2,
                pageResetNextClick: false
            });
        }

        if (!this.props.selectionModeEnabled && prevProps.selectionModeEnabled)  {
            var showPolyline = [];
            var lat1 = [];
            var long1 = [];
            var lat2 = [];
            var long2 = [];

            //Update refs where required
            if (this.props.capturedataLoaded) {
                this.props.capturedata.map((row, index) => {
                    if (this.captureCircleRefs[index].current != null) {
                        this.captureCircleRefs[index].current.setStyle(this.props.styleMap.HoleData_Captured || { fillOpacity: 0.2, fillColor: 'green' })
                    }
                });
            }
            if (this.props.missingdataLoaded) {
                this.props.missingdata.map((row, index) => {
                    if (this.missingCircleRefs[index].current != null) {
                        this.missingCircleRefs[index].current.setStyle(this.props.styleMap.HoleData_Missing || { fillOpacity: 0.2, fillColor: 'red' })
                    }
                    showPolyline.push(false);
                    lat1.push(0);
                    long1.push(0);
                    lat2.push(0);
                    long2.push(0);
                });
            }
            this.setState({
                //Init - config
                pageCaptureClickedIndex: -1,
                pageCaptureClickedData: {},
                pageMissingClickedIndex: -1,
                pageMissingClickedData: {},
                pageMissingShowPolyline: showPolyline,
                pageMissingShowPolyline_Latitude1: lat1,
                pageMissingShowPolyline_Longitude1: long1,
                pageMissingShowPolyline_Latitude2: lat2,
                pageMissingShowPolyline_Longitude2: long2,
                pageResetNextClick: false
            });
        }
    }

    overrideColorsForSelection(capturedataIndex, capturedataData, missingdataIndex, missingdataData) {
        var showPolyline = this.state.pageMissingShowPolyline;
        var lat1 = this.state.pageMissingShowPolyline_Latitude1;
        var long1 = this.state.pageMissingShowPolyline_Longitude1;
        var lat2 = this.state.pageMissingShowPolyline_Latitude2;
        var long2 = this.state.pageMissingShowPolyline_Longitude2;

        //Mark clicked black
        if (capturedataIndex >= 0) {
            if (this.state.pageCaptureClickedIndex != null) {
                if ((this.state.pageCaptureClickedIndex != capturedataIndex) && (this.state.pageCaptureClickedIndex >= 0)) {
                    //Set old one back to original color
                    this.captureCircleRefs[this.state.pageCaptureClickedIndex].current.setStyle(this.props.styleMap.HoleData_Captured || { fillOpacity: 0.2, fillColor: 'green' })
                }
            }
            this.captureCircleRefs[capturedataIndex].current.setStyle(this.props.styleMap.Review_Clicked || { fillOpacity: 1, fillColor: 'black' })
        }
        if (missingdataIndex >= 0) {
            if (this.state.pageMissingClickedIndex != null) {
                if ((this.state.pageMissingClickedIndex != missingdataIndex) && (this.state.pageMissingClickedIndex >= 0)) {
                    //Set old one back to original color
                    this.missingCircleRefs[this.state.pageMissingClickedIndex].current.setStyle(this.props.styleMap.HoleData_Missing || { fillOpacity: 0.2, fillColor: 'red' })
                }
            }
            this.missingCircleRefs[missingdataIndex].current.setStyle(this.props.styleMap.Review_Clicked || { fillOpacity: 1, fillColor: 'black' })
        }
        if ((capturedataIndex >= 0) && (missingdataIndex >= 0)) {
            if (this.missingPolylineRefs[missingdataIndex].current != null) {
                this.missingPolylineRefs[missingdataIndex].current.setStyle(this.props.styleMap.Review_Clicked || { fillOpacity: 1, fillColor: 'black' }) //done need to switch off as isnt shown
            }
            lat1[missingdataIndex] = capturedataData.Latitude;
            long1[missingdataIndex] = capturedataData.Longitude;
            lat2[missingdataIndex] = missingdataData.Latitude;
            long2[missingdataIndex] = missingdataData.Longitude;
            showPolyline[missingdataIndex] = true;
        } else {
            //No longer show polyline
            showPolyline[this.state.pageMissingClickedIndex] = false;
        }

        //Mark previous array brown
        var index;
        if (this.props.selectionModeEnabled) {
            for (var i = 0; i < this.props.selectionModeSelections.length; i++) {
                index = this.props.selectionModeSelections[i]['capture_rowIndex'];
                if ((index != null) && (index >= 0)) {
                    this.captureCircleRefs[index].current.setStyle(this.props.styleMap.Review_ClickedPrevious || { fillOpacity: 1, fillColor: 'brown' })
                }
                index = this.props.selectionModeSelections[i]['missing_rowIndex'];
                if ((index != null) && (index >= 0)) {
                    this.missingCircleRefs[index].current.setStyle(this.props.styleMap.Review_ClickedPrevious || { fillOpacity: 1, fillColor: 'brown' })
                    if (this.missingPolylineRefs[index].current != null) {
                        this.missingPolylineRefs[index].current.setStyle(this.props.styleMap.Previous || { fillOpacity: 1, fillColor: 'brown' }) //done need to switch off as isnt shown
                    }
                    lat1[index] = this.props.selectionModeSelections[i]["capture_rowData"].Latitude;
                    long1[index] = this.props.selectionModeSelections[i]["capture_rowData"].Longitude;
                    lat2[index] = this.props.selectionModeSelections[i]["missing_rowData"].Latitude;
                    long2[index] = this.props.selectionModeSelections[i]["missing_rowData"].Longitude;
                    showPolyline[index] = true;
                }
            }
        }

        this.setState({
            //Store
            pageCaptureClickedIndex: capturedataIndex,
            pageCaptureClickedData: capturedataData,
            pageMissingClickedIndex: missingdataIndex,
            pageMissingClickedData: missingdataData,
            pageMissingShowPolyline: showPolyline,
            pageMissingShowPolyline_Latitude1: lat1,
            pageMissingShowPolyline_Longitude1: long1,
            pageMissingShowPolyline_Latitude2: lat2,
            pageMissingShowPolyline_Longitude2: long2,
        });
    }

    clickEventCapture(e) {
        if (this.props.selectionModeEnabled) {
            console.log('capture marker clicked', e.target.options.rowData); // logs position.
            if (e.target.options.rowIndex != null) {
                ///Check if already selected
                var alreadySelected = false
                var index;
                for (var i = 0; i < this.props.selectionModeSelections.length; i++) {
                    index = this.props.selectionModeSelections[i]['capture_rowIndex'];
                    if ((index != null) && (index >= 0)) {
                        if (e.target.options.rowIndex == index) {
                            //match found - no valid hole selected
                            alreadySelected = true;
                            break;
                        }
                    }
                }
                if (!alreadySelected) {
                    if ((this.state.pageMissingClickedIndex >= 0) && !this.state.pageResetNextClick) {
                        //Have both clicks - store in props
                        this.props.selectionModeUpdate({
                            'capture_rowIndex': e.target.options.rowIndex,
                            'capture_rowData': e.target.options.rowData,
                            'missing_rowIndex': this.state.pageMissingClickedIndex,
                            'missing_rowData': this.state.pageMissingClickedData,
                        });
                    }
                    if (this.state.pageResetNextClick) {
                        //switch off polyline temporarily
                        if (this.state.pageMissingClickedIndex != null) {
                            if (this.state.pageMissingClickedIndex >= 0) {
                                var polyline = this.state.pageMissingShowPolyline
                                polyline[this.state.pageMissingClickedIndex] = false;
                                this.setState({
                                    pageMissingShowPolyline: polyline,
                                });
                            }
                        }
                        this.setState({
                            pageResetNextClick: false,      
                            pageCaptureClickedIndex: -1,
                            pageCaptureClickedData: {},
                            pageMissingClickedIndex: -1,
                            pageMissingClickedData: {},
                        });
                    }
                    if (this.state.pageMissingClickedIndex >= 0) {
                        this.setState({
                            pageResetNextClick: true
                        });
                    }
                    this.overrideColorsForSelection(e.target.options.rowIndex, e.target.options.rowData, this.state.pageMissingClickedIndex, this.state.pageMissingClickedData);
                }
            }
        }
    }

    clickEventMissing(e) {
        if (this.props.selectionModeEnabled) {
            console.log('plan marker clicked', e.target.options.rowData); // logs position.
            if (e.target.options.rowIndex != null) {
                ///Check if already selected
                var alreadySelected = false
                var index;
                for (var i = 0; i < this.props.selectionModeSelections.length; i++) {
                    index = this.props.selectionModeSelections[i]['missing_rowIndex'];
                    if ((index != null) && (index >= 0)) {
                        if (e.target.options.rowIndex == index) {
                            //match found - no valid hole selected
                            alreadySelected = true;
                            break;
                        }
                    }
                }
                if (!alreadySelected) {
                    if ((this.state.pageCaptureClickedIndex >= 0) && !this.state.pageResetNextClick) {
                        //Have both clicks - store in props
                        this.props.selectionModeUpdate({
                            'capture_rowIndex': this.state.pageCaptureClickedIndex,
                            'capture_rowData': this.state.pageCaptureClickedData,
                            'missing_rowIndex': e.target.options.rowIndex,
                            'missing_rowData': e.target.options.rowData,
                        });
                    }
                    //Check for reset first
                    if (this.state.pageResetNextClick) {
                        if (e.target.options.rowIndex != null) {
                            if (e.target.options.rowIndex >= 0) {
                                var polyline = this.state.pageMissingShowPolyline
                                polyline[e.target.options.rowIndex] = false;
                                this.setState({
                                    pageMissingShowPolyline: polyline,
                                });
                            }
                        }
                        this.setState({
                            pageResetNextClick: false,
                            pageCaptureClickedIndex: -1,
                            pageCaptureClickedData: {},
                            pageMissingClickedIndex: -1,
                            pageMissingClickedData: {},
                        });
                    }
                    if (this.state.pageCaptureClickedIndex >= 0) {
                        this.setState({
                            pageResetNextClick: true
                        });
                    }
                    this.overrideColorsForSelection(this.state.pageCaptureClickedIndex, this.state.pageCaptureClickedData, e.target.options.rowIndex, e.target.options.rowData);
                }
            }
        }
    }

    createbounds(data) {
        var findindex = 0;
        for (var i = 0; i < data.length; i++) {
            if (data[i].Latitude != 0 && data[i].Longitude != 0) {
                findindex = i;
                break;
            }
        }
        return findindex;
    }

    extendbounds(data, bounds) {
        data.map(row => {
            if (row.Latitude != 0 && row.Longitude != 0) {
                //Valid GPS locatiosn only
                bounds.extend([row.Latitude, row.Longitude])
            }
        })
        if (data.length == 1) {
            bounds.extend([data[0].Latitude + 0.001, data[0].Longitude + 0.001]) //to not have single latlong other the extend function crashes
        }
        if (!bounds.isValid()) { //is bounds not valid, put generic (to stop map crashing)
            bounds.extend([0.001, 0.001]);
            bounds.extend([-0.001, -0.001]);
        }
    }

    render() {
        var findindex;
        var data;
        if (this.props.capturedataLoaded) {
            findindex = this.createbounds(this.props.capturedata);
            data = this.props.capturedata;

        }
        if (this.props.plandataLoaded) {
            findindex = this.createbounds(this.props.plandata);
            data = this.props.plandata;
        }
        const center = [data[findindex].Latitude, data[findindex].Longitude]
        const bounds = latLngBounds(data[findindex].Latitude, data[findindex].Longitude)
        this.extendbounds(data, bounds);

        return (
            <div id="mapid">
                <MapContainer whenCreated={mapInstance => { this.props.PDFref.current = mapInstance }} center={center} preferCanvas={true} scrollWheelZoom={true} style={{ height: "100%", width: "100%" }} bounds={bounds} boundsOptions={{ padding: [100, 100] }}>
                    <LayersControl position="topright">
                        {this.props.tilelayerenabled &&
                            <LayersControl.BaseLayer checked name="Satellite">
                            <TileLayer maxNativeZoom={17} maxZoom={100}
                                attribution='Powered by<a href="https://www.esri.com/">ESRI</a>'
                                url={"https://ibasemaps-api.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=" + window._env_.REACT_APP_ESRIMAP_CLIENTAPIKEY}
                                />
                            </LayersControl.BaseLayer>
                        }
                        {this.props.tilelayerenabled &&
                            <LayersControl.BaseLayer name="Base">
                                <TileLayer maxNativeZoom={18} maxZoom={100}
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                        }
                        <LayersControl.Overlay checked name="Plan">
                            <FeatureGroup pathOptions={{ color: this.props.styleMap?.HoleData_Plan && this.props.styleMap.HoleData_Plan.color || 'grey' }}>
                              {this.props.plandataLoaded &&
                                      this.props.plandata.map(row =>
                                          row.Latitude != 0 && row.Longitude != 0 ?
                                              <div>
                                                  <Circle center={[row.Latitude, row.Longitude]} radius={this.props.styleMap?.HoleData_Plan && this.props.styleMap.HoleData_Plan.radius || 0.5}>
                                                      <Tooltip>
                                                          <div>
                                                              <h5>Assoc Capture ID: {row.AssocCaptureId}</h5>
                                                              <h5>Assoc Capture Dist: {row.AssocCaptureDistDiff}</h5>
                                                          </div>
                                                      </Tooltip>
                                                  </Circle>
                                              </div>
                                            : <div></div>
                                        )}
                             </FeatureGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Missing">
                            <FeatureGroup pathOptions={{ color: this.props.styleMap?.HoleData_Missing && this.props.styleMap.HoleData_Missing.color || 'red' }}>
                                {this.props.missingdataLoaded && (this.props.missingdata.length == this.missingCircleRefs.length) &&
                                    this.props.missingdata.map((row,index) =>
                                        row.Latitude != 0 && row.Longitude != 0 ?
                                            <div>
                                                <Circle center={[row.Latitude, row.Longitude]} radius={this.props.styleMap?.HoleData_Missing && this.props.styleMap.HoleData_Missing.radius || 0.5} rowData={row} rowIndex={index} eventHandlers={{ click: (e) => { this.clickEventMissing(e) } }} ref={(ref) => { this.missingCircleRefs[index].current = ref; }}>
                                                    <Tooltip>
                                                        <div>
                                                            <h5>ID: {row.Name}</h5>
                                                        </div>
                                                    </Tooltip>
                                                </Circle>
                                            </div>
                                            : <div></div>
                                    )}
                            </FeatureGroup>
                            {this.props.selectionModeEnabled &&
                                <FeatureGroup pathOptions={{ color: this.props.styleMap?.Review_Polyline && this.props.styleMap.Review_Polyline.color || 'black' }}>
                                {this.props.missingdataLoaded && (this.props.missingdata.length == this.missingPolylineRefs.length) &&
                                        this.props.missingdata.map((row, index) =>
                                            row.Latitude != 0 && row.Longitude != 0 ?
                                                <div>
                                                    {(this.state.pageMissingShowPolyline[index]) &&
                                                        <Polyline positions={[[this.state.pageMissingShowPolyline_Latitude1[index], this.state.pageMissingShowPolyline_Longitude1[index]], [this.state.pageMissingShowPolyline_Latitude2[index], this.state.pageMissingShowPolyline_Longitude2[index]]]} ref={(ref) => { this.missingPolylineRefs[index].current = ref; }} />
                                                    }
                                                </div>
                                                : <div></div>
                                        )}
                                </FeatureGroup>
                            }
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Diff">
                            <FeatureGroup pathOptions={{ color: this.props.styleMap?.Review_Diff && this.props.styleMap.Review_Diff.color || 'orange' }}>
                                {this.props.diffdataLoaded &&
                                    this.props.diffdata.map(row =>
                                        row.Latitude_Plan != 0 && row.Longitude_Plan != 0 && row.Latitude_Capture != 0 && row.Longitude_Capture != 0?
                                            <div>
                                                <Circle center={[row.Latitude_Capture, row.Longitude_Capture]} radius={this.props.styleMap?.Review_Diff && this.props.styleMap.Review_Diff.radius || 0.5}>
                                                    <Tooltip>
                                                        <div>
                                                            <h5>Diff Hole Depth: {row.Diff_HoleDepth}</h5>
                                                            <h5>Diff Toe RL: {row.Diff_ToeRL}</h5>
                                                        </div>
                                                    </Tooltip>
                                                </Circle>
                                                <Polyline positions={ [[row.Latitude_Plan, row.Longitude_Plan], [row.Latitude_Capture, row.Longitude_Capture]] } />
                                            </div>
                                            : <div></div>
                                    )}
                            </FeatureGroup>
                        </LayersControl.Overlay>
                        <LayersControl.Overlay checked name="Captures">
                            <FeatureGroup pathOptions={{ color: this.props.styleMap?.HoleData_Captured && this.props.styleMap.HoleData_Captured.color || 'green' }}>
                                {this.props.capturedataLoaded && (this.props.capturedata.length == this.captureCircleRefs.length) &&
                                    this.props.capturedata.map((row, index) =>
                                        row.Latitude != 0 && row.Longitude != 0 ?
                                            <div>
                                                <Circle center={[row.Latitude, row.Longitude]} radius={this.props.styleMap?.HoleData_Captured && this.props.styleMap.HoleData_Captured.radius || 0.5} rowData={row} rowIndex={index} eventHandlers={{ click: (e) => { this.clickEventCapture(e) } }} ref={(ref) => { this.captureCircleRefs[index].current = ref; }}>
                                                    <Tooltip>
                                                        <div>
                                                            <h5>Capture ID: {row.id}</h5>
                                                            <h5>DateTime: {row.dateTimeLocalString}</h5>
                                                            <h5>Depth Measured(mm): {row.depthhole_rounded}</h5>
                                                            <h5>Toe RL(m): {JSON.parse(row.data).toerl}</h5>
                                                            <h5>Reflectivity(%): {row.reflecthole_data}</h5>
                                                            <h5>Points Collected: {JSON.parse(row.data).pointcount}</h5>
                                                            <h5>Capture Type: {row.capturetype_data}</h5>
                                                        </div>
                                                    </Tooltip>
                                                </Circle>
                                            </div>
                                            : <div></div>
                                    )}
                            </FeatureGroup>
                        </LayersControl.Overlay>
                      </LayersControl>
                    </MapContainer>
                </div>
        );
    }
}